<template>
  <div class="course-slug">
    <h1>{{ title }}</h1>
    <PhrasesList :isDemo="false" :course="this.$route.params.courseslug"></PhrasesList>
  </div>
</template>

<script>
import PhrasesList from '@/components/PhrasesList'

export default {
  name: "slug",
  data() {
    return {
      phrases: [],
      disableLoadMoreButton: false,
    };
  },
  computed: {
    title() {
      return this.$route.params.courseslug
        .toLowerCase()
        .replace(/\b(\w)/g, (s) => s.toUpperCase());
    },
  },
  components: {
    PhrasesList,
  },
};
</script>


<style scoped lang="scss">
.course-slug {
  padding: 0.5rem;
  h1 {
    margin: 0;
  }
}
</style>