<template>
  <div class="phraseslist">
    <template v-if="filteredPhrases">
      <div class="filters">
        <span>Display: </span>
        <input type="checkbox" id="english" value="english" v-model="showEnglish">
        <label for="english">English</label>
        <input type="checkbox" id="transcript" value="transcript" v-model="showTranscript">
        <label for="transcript">Transcript</label>
      </div>

      <div class="search">
        <input type="search" placeholder="Search for phrase" v-model="search" />
      </div>
      <template v-if="!isFetchingInitial">
        <div class="phrases" v-for="phrase in filteredPhrases" v-bind:key="phrase.id">
          <div class="card" v-bind:class="{ hideTranscript: !showTranscript, hideEnglish: !showEnglish }">
            <div class="audio" @click="playAudio(phrase.thai.audio, phrase.thai.text)">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ic" width="32" height="32" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                <path d="M12 5v8.55c-.94-.54-2.1-.75-3.33-.32c-1.34.48-2.37 1.67-2.61 3.07a4.007 4.007 0 0 0 4.59 4.65c1.96-.31 3.35-2.11 3.35-4.1V7h2c1.1 0 2-.9 2-2s-.9-2-2-2h-2c-1.1 0-2 .9-2 2z" fill="currentColor"></path>
              </svg>
            </div>
            <div class="copy" v-clipboard:copy="phrase.thai.text" v-clipboard:success="onCopy">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--mdi" width="32" height="32" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                <path d="M19 21H8V7h11m0-2H8a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2m-3-4H4a2 2 0 0 0-2 2v14h2V3h12V1z" fill="currentColor"></path>
              </svg>
            </div>
            <div class="star" @click="favorite(phrase.id, phrase.thai.text)">
              <svg v-if="isStarred(phrase.id)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ic" width="32" height="32" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z" fill="#FFAC33"></path></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ic" width="32" height="32" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M19.65 9.04l-4.84-.42l-1.89-4.45c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.73l3.67-3.18c.67-.58.32-1.68-.56-1.75zM12 15.4l-3.76 2.27l1-4.28l-3.32-2.88l4.38-.38L12 6.1l1.71 4.04l4.38.38l-3.32 2.88l1 4.28L12 15.4z" fill="currentColor"></path></svg>
            </div>
            <div class="text">
              <div class="thai">{{ phrase.thai.text }}</div>
              <div class="transcript">{{ phrase.thai.transcript }}</div>
              <div class="english">{{ phrase.english.text }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="isFetchingInitial">
        <div class="phrases" v-for="i in 10" v-bind:key="i">
          <div class="placeholder">
            <Placeholder></Placeholder>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Placeholder from '@/components/Placeholder'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    isDemo: { required: true, default: false },
    course: { required: false, default: null },
    count: { required: false, default: 10 },
    lastUid: { required: false, default: null },
  },
  data() {
    return {
      phrases: [],
      categories: null,
      isFetchingInitial: true,
      isFetchingMore: false,
      search: "",
      lockAudio: false,
      showTranscript: false,
      showEnglish: true,
      demoStarred: [],
      starPhrases: [],
    };
  },
  computed: {
    ...mapGetters([
      'demoPhrases',
    ]),
    filteredPhrases() {
      let phrases = []
      if (this.isDemo) {
        phrases = this.demoPhrases
      } else {
        phrases = this.phrases
      }
      return phrases.filter((phrase) => {
        return (
          phrase.english.tags.includes(this.search.toLowerCase()) ||
          phrase.english.text.toLowerCase().includes(this.search.toLowerCase()) ||
          phrase.thai.text.includes(this.search)
        );
      });
    },
  },
  methods: {
    ...mapActions(["getPhrases"]),
    playAudio(audioId, thai) {
      console.log("play: " + audioId, thai);
      if (!this.lockAudio) {
        this.lockAudio = true
        setTimeout(() => this.lockAudio = false, 1500);
        var audio = new Audio(`/audio/${audioId}`)
        audio.play()
        this.$notify.success(`Playing: ${thai}`)
      }
    },
    favorite(id, text) {
      if (this.isDemo) {
        if (this.demoStarred.includes(id)) {
          this.demoStarred.splice(this.demoStarred.indexOf(id), 1);
          this.$notify.success(`Removed: ${text}`)
        } else {
          this.demoStarred.push(id);
          this.$notify.success(`Added: ${text}`)
        }
      } else {
        if (this.starPhrases.includes(id)) {
          this.starPhrases.splice(this.starPhrases.indexOf(id), 1);
          this.$notify.success(`Removed: ${text}`)
        } else {
          this.starPhrases.push(id);
          this.$notify.success(`Added: ${text}`)
        }
      }
    },
    onCopy: function (e) {
      this.$notify.success(`Copied: ${e.text}`)
    },
    isStarred(id) {
      if (this.isDemo) {
        if (this.demoStarred.includes(id)) {
          return true
        }
        return false
      }
      if (this.starPhrases.includes(id)) {
        return true
      }
      return false
    },
    async fetch() {
      console.log('phrase list:', this.categories)
      if (this.phrases.length > 0) {
        this.lastUid = this.phrases[this.phrases.length - 1].id;
      }
      const { data, hasError, hasMore } = await this.getPhrases({
        lastUid: this.lastUid,
        count: 10,
        categories: this.categories,
      });
      this.disableLoadMoreButton = !hasMore;
      if (this.lastUid === null) {
        this.phrases = data;
      } else {
        this.phrases = [...this.phrases, ...data];
      }
      this.isFetchingInitial = false
      console.log(hasError);
      console.log(data);
      console.log("hasMore:", hasMore);
    },
  },
  mounted() {
    if (this.course) this.categories = [this.course]
    if (this.isDemo) {
      this.isFetchingInitial = false
    } else {
      this.fetch();
    }
  },
  components: {
    Placeholder,
  },
}
</script>

<style lang="scss" scoped>
.phraseslist {
  .phrases {
    padding: 0.25rem 0;
    .card {
      background-color: var(--phrase-background-color);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.1);
      padding: 0.25rem;
      display: grid;
      grid-template-areas:
        "audio text copy"
        "audio text star";
      grid-template-columns: max-content 1fr max-content;
      text-align: left;
      .audio,
      .star,
      .copy {
        height: 100%;
        display: flex;
        cursor: pointer;
      }
      .audio {
        grid-area: audio;
        svg {
          max-height: 2.25rem;
          max-width: 2.25rem;
        }
      }
      .star {
        grid-area: star;
        svg {
          max-height: 1rem;
          max-width: 1rem;
          align-self: end;
          align-self: flex-end;
        }
      }
      .copy {
        grid-area: copy;
        svg {
          max-height: 1rem;
          max-width: 1rem;
          align-self: start;
          align-self: flex-start;
        }
      }
      .text {
        grid-area: text;
      }
      .thai {
        grid-area: thai;
      }
      .transcript {
        grid-area: transcript;
      }
      svg {
        color: var(--phrase-svg-color);
        align-self: center;
      }
    }
    .placeholder {
      height: 2.75rem;
    }
    .hideTranscript {
      .transcript {
        display: none;
      }
    }
    .hideEnglish {
      .english {
        display: none;
      }
    }
  }
  .search {
    margin: 0.5rem 0;
    input {
      padding: 0 0.5rem;
      width: 100%;
      height: 2rem;
      box-sizing: border-box;
      border: none;
      border-radius: 0.25rem;
      -webkit-appearance: none;
      margin: 0;
      color: #5f6c7b;
      background: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.1);
      font-size: 16px;
    }
  }
}
</style>